import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Pagetitle, Card, toDateString, toTimeString } from '../../components';
import { Editor } from '@tinymce/tinymce-react';
import app from '../../config/base';


const ArticleEdit = props => {

  const [loading, setLoading] = useState(true);

  const [article, setArticle] = useState({
    id: '',
    author: '',
    title: '',
    content: '',
    createdAt: '',
  });

  const deleteArticle = () => {
    setLoading(true);
    app.firestore().doc('/news/' + props.match.params.id).delete().then(() => {
      toast.success('Beitrag gelöscht');
      props.history.push('/articles');
    }).catch(error => {
      setLoading(false);
      toast.error(error);
    });
  }

  const handleInputChange = e => setArticle({
    ...article,
    [e.currentTarget.name]: e.currentTarget.value,
  });

  const validateForm = () => {
    if (article.title.trim() === '') {
      toast.error('Titel ist erforderlich');
      return false;
    }
    if (article.content.trim() === '') {
      toast.error('Nachricht ist erforderlich');
      return false;
    }
    return true;
  }

  const handleUpdate = () => {
    if (validateForm()) {
    setLoading(true);
      app.firestore().doc('/news/' + article.id).update({
        title: article.title,
        content: article.content,
      }).then(() => {
        setLoading(false);
        props.history.push('/articles');
        toast.success('Beitrag aktualisiert');
      }).catch(error => {
        setLoading(false);
        toast.error('Beitrag gelöscht');
      }); 
    }
  }

  useEffect(() => {
    if (article.id === '') {
      setLoading(true);
      app.firestore().doc('/news/' + props.match.params.id).get().then(doc => {
        setLoading(false);
        setArticle({
          id: doc.id,
          title: doc.data().title,
          content: doc.data().content,
          author: doc.data().author,
          createdAt: doc.data().createdAt,
        });
      });
    }
  }, [props.match.params.id, article]);

  return (
    <>
      <Pagetitle icon="newspaper" title="Beitrag bearbeiten" />

      <Link to="/articles" className="btn btn-transparent mb-30">
        <FontAwesomeIcon className="mr-2" icon="arrow-left" />Alle Beiträge
      </Link>

      <div className="row">
        <div className="col-md-9">
          <Card>
            <input type="text" className="form m-0" name="title" value={article.title} onChange={handleInputChange} placeholder="Titel" />
          </Card>
          <Card>
            <Editor
              apiKey="82s1jwskr7fan26fsy1jmeepsqgzed5euchlzngruckiweuu"
              value={article.content}
              onEditorChange={(content) => setArticle({
                ...article,
                content,
              })}
              init={{
                height: '500px',
                menu: {
                  view: { title: 'View', items: 'code' },
                },
                plugins: 'code image',
                menubar: 'file edit insert view format',
              }}
            />
          </Card>
        </div>
        <div className="col-md-3">
          <Card>
            <table>
              <tbody>
                <tr>
                  <td>Erstellt:</td>
                  <td>
                    <b>
                      {toDateString(article.createdAt.seconds)}<br />
                      {toTimeString(article.createdAt.seconds)}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Autor:</td>
                  <td><b>{article.author}</b></td>
                </tr>
              </tbody>
            </table>
            {
              loading ?
                <div className="loading"><div></div><div></div><div></div></div>
              :
                <>
                  <button className="btn w-100 my-2" onClick={handleUpdate}>
                    <FontAwesomeIcon className="mr-3" icon="paper-plane" />Aktualisieren
                  </button>
                  <span className="delete-btn" onClick={deleteArticle}>Beitrag löschen</span>
                </>
            }
          </Card>
        </div>
      </div>
    </>
  )
};

export default ArticleEdit;