import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Pagetitle, Card } from '../../components';
import app from '../../config/base';


const JobCreate = props => {

  const [loading, setLoading] = useState(false);

  const [aufgaben, setAufgaben] = useState([]);
  const [qualifikationen, setQualifikationen] = useState([]);
  const [vorteile, setVorteile] = useState([]);
  const [title, setTitle] = useState('');
  const [adema, setAdema] = useState('köln');
  const [branche, setBranche] = useState('Altenpflege');
  const [ort, setOrt] = useState('');
  const [zeit, setZeit] = useState('');
  const [lohn, setLohn] = useState('');


  const handleArrayChange = (array, e, i) => {
    if (array === 'aufgaben') {
      let tempAufgaben = aufgaben;
      tempAufgaben[i] = e.target.value;
      setAufgaben([...tempAufgaben]);
    }
    if (array === 'qualifikationen') {
      let tempQualifikationen = qualifikationen;
      tempQualifikationen[i] = e.target.value;
      setQualifikationen([...tempQualifikationen]);
    }
    if (array === 'vorteile') {
      let tempVorteile = vorteile;
      tempVorteile[i] = e.target.value;
      setVorteile([...tempVorteile]);
    }
  }

  const deleteFromArray = (array, i) => {
    if (array === 'aufgaben') {
      let tempAufgaben = [...aufgaben];
      tempAufgaben.splice(i, 1);
      setAufgaben(tempAufgaben);
    }
    if (array === 'qualifikationen') {
      let tempQualifikationen = [...qualifikationen];
      tempQualifikationen.splice(i, 1);
      setQualifikationen(tempQualifikationen);
    }
    if (array === 'vorteile') {
      let tempVorteile = [...vorteile];
      tempVorteile.splice(i, 1);
      setVorteile(tempVorteile);
    }
  }

  const validateForm = () => {
    if (title.trim() === '') {
      toast.error('Titel ist erforderlich');
      return false;
    }
    return true;
  }

  const handleCreate = () => {
    if (validateForm()) {
      setLoading(true);
      app.firestore().collection('/stellenangebote').add({
        adema,
        title,
        aufgaben,
        qualifikationen,
        vorteile,
        branche,
        ort,
        zeit,
        lohn,
      }).then(() => {
        setLoading(false);
        props.history.push('/jobs');
        toast.success('Stellenangebot aktualisiert');
      }).catch(error => {
        setLoading(false);
        toast.error('Fehler beim veröffentlichen');
      }); 
    }
  }

  return (
    <>
      <Pagetitle icon="id-card" title="Stellenagebot erstellen" />

      <Link to="/jobs" className="btn btn-transparent mb-30">
        <FontAwesomeIcon className="mr-2" icon="arrow-left" />Alle Stellenagebote
      </Link>

      <div className="row">
        <div className="col-md-9">
          <Card>
            <input type="text" className="form m-0" value={title} onChange={e => setTitle(e.target.value)} placeholder="Titel" />
          </Card>

          <Card title="Aufgaben">
            {
              aufgaben.map((aufgabe, i) => (
                <div className="row" key={i}>
                  <div className="col-11">
                    <input type="text" className="form" value={aufgaben[i]} onChange={(e) => handleArrayChange('aufgaben', e, i)} />
                  </div>
                  <div className="col-1">
                    <button type="button" className="btn btn-danger btn-absolute" onClick={() => deleteFromArray('aufgaben', i)}>
                      <FontAwesomeIcon icon="trash-alt" />
                    </button>
                  </div>
                </div>
              ))
            }
            <button type="button" className="btn" onClick={() => setAufgaben(aufgaben => [...aufgaben, ''])}>
              <FontAwesomeIcon icon="plus" />
            </button>
          </Card>

          <Card title="Qualifikationen">
            {
              qualifikationen.map((qualifikation, i) => (
                <div className="row" key={i}>
                  <div className="col-11">
                    <input type="text" className="form" value={qualifikationen[i]} onChange={(e) => handleArrayChange('qualifikationen', e, i)} />
                  </div>
                  <div className="col-1">
                    <button type="button" className="btn btn-danger btn-absolute" onClick={() => deleteFromArray('qualifikationen', i)}>
                      <FontAwesomeIcon icon="trash-alt" />
                    </button>
                  </div>
                </div>
              ))
            }
            <button type="button" className="btn" onClick={() => setQualifikationen(qualifikationen => [...qualifikationen, ''])}>
              <FontAwesomeIcon icon="plus" />
            </button>
          </Card>

          <Card title="Vorteile">
            {
              vorteile.map((vorteil, i) => (
                <div className="row" key={i}>
                  <div className="col-11">
                    <input type="text" className="form" value={vorteile[i]} onChange={(e) => handleArrayChange('vorteile', e, i)} />
                    </div>
                  <div className="col-1">
                    <button type="button" className="btn btn-danger btn-absolute" onClick={() => deleteFromArray('vorteile', i)}>
                      <FontAwesomeIcon icon="trash-alt" />
                    </button>
                  </div>
                </div>
              ))
            }
            <button type="button" className="btn" onClick={() => setVorteile(vorteile => [...vorteile, ''])}>
              <FontAwesomeIcon icon="plus" />
            </button>
          </Card>
        </div>
        <div className="col-md-3">
          <Card>
            <table>
              <tbody>
                <tr>
                  <td>ADEMA:</td>
                  <td>
                    <select value={adema} className="form m-0" onChange={e => setAdema(e.target.value)}>
                      <option value="köln">Köln</option>
                      <option value="düsseldorf">Düsseldorf</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Branche:</td>
                  <td>
                    <select value={branche} className="form m-0" onChange={e => setBranche(e.target.value)}>
                      <option value="Altenpflege">Altenpflege</option>
                      <option value="Lebensmittel">Lebensmittel</option>
                      <option value="Handwerk">Handwerk</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Ort:</td>
                  <td>
                    <input type="text" className="form m-0" value={ort} onChange={e => setOrt(e.target.value)} />
                  </td>
                </tr>
                <tr>
                  <td>Zeit:</td>
                  <td>
                    <input type="text" className="form m-0" value={zeit} onChange={e => setZeit(e.target.value)} />
                  </td>
                </tr>
                <tr>
                  <td>Lohn:</td>
                  <td>
                    <input type="text" className="form m-0" value={lohn} onChange={e => setLohn(e.target.value)} />
                  </td>
                </tr>
              </tbody>
            </table>
            {
              loading ?
                <div className="loading"><div></div><div></div><div></div></div>
              :
                <>
                  <button className="btn w-100 my-2" onClick={handleCreate}>
                    <FontAwesomeIcon className="mr-3" icon="paper-plane" />Veröffentlichen
                  </button>
                </>
            }
          </Card>
        </div>
      </div>
    </>
  )
};

export default JobCreate;