import React, { useContext, useState } from "react";
import { GlobalContext, createUser } from "../../context/store";
import { Card, Pagetitle, toDateString, Modal } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./members.css";
import { toast } from "react-toastify";
import base from "../../config/base";

const Members = props => {
  const { users } = useContext(GlobalContext);

  const [columns, setColumns] = useState([
    { name: "Bild", key: "photoURL", type: "image", active: true },
    { name: "E-Mail", key: "email", type: "text", active: true },
    { name: "Vorname", key: "firstname", type: "text", active: true },
    { name: "Nachname", key: "lastname", type: "text", active: true },
  ]);

  const [dropdown, setDropdown] = useState(false);

  const [modalNewUserOpen, setmodalNewUserOpen] = useState(false);
  const [modalEditUserOpen, setmodalEditUserOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState({});

  const [modalEmail, setModalEmail] = useState('');
  const [modalPassword, setModalPassword] = useState('');
  const [modalLoading, setModalLoading] = useState(false);

  const toggleDropdown = () => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const toggleColumn = (e, i) => {
    setColumns(columns.map((column, j) => (i === j ? { ...column, active: e.target.checked } : column)));
  };

  const newUser = () => {
    setModalLoading(true);
    createUser(modalEmail, modalPassword).then(request => {
      setmodalNewUserOpen(false);
      toast.success('Mitarbeiter erstellt');
      setModalEmail('');
      setModalPassword('');
      setModalLoading(false);
    }).catch(err => toast.error('Error!'));
  }

  const updateUser = () => {
    setModalLoading(true);
    base.firestore().doc('users/' + currentUser.id).update(currentUser).then(() => {
      toast.success('Mitarbeiter aktualisiert');
      setmodalEditUserOpen(false);
      setModalLoading(false);
    });
  }

  const deleteUser = () => {
    setModalLoading(true);
  }

  const renderColumnCheckbox = (column, i) => {
    return (
      <li key={i}>
        <label htmlFor={"checkbox_" + column.key}>
          <input type="checkbox" id={"checkbox_" + column.key} onChange={e => toggleColumn(e, i)} checked={column.active} /> {column.name}
        </label>
      </li>
    );
  };

  const renderColumns = (user, i) => {
    return columns.map((column, j) =>
      column.active ? (
        column.type === "text" ? (
          <td key={j}>{user[column.key]}</td>
        ) : column.type === "email" ? (
          <td key={j}>
            <a href={"mailto:" + user[column.key]}>{user[column.key]}</a>
          </td>
        ) : column.type === "date" ? (
          <td key={j}>{toDateString(user[column.key])}</td>
        ) : column.type === "boolean" ? (
          <td>
            {
              user[column.key] ? 'Ja' : 'Nein'
            }
          </td>
        ) : column.type === "image" ? (
          <td key={j}>
            <img width="40" src={user[column.key]} alt="User Thumbnail" />
          </td>
        ) : null
      ) : null,
    );
  };

  return (
    <>
      <Pagetitle icon="users" title={"Mitarbeiter (" + users.get.length + ")"} />

      <div className="mb-30" style={{ position: "relative" }}>
        <button onClick={() => setmodalNewUserOpen(true)} className="btn">
          <FontAwesomeIcon icon="plus" /> Neuer Mitarbeiter
        </button>

        <button onClick={toggleDropdown} type="button" className="btn btn-white" style={{ float: "right" }}>
          <FontAwesomeIcon icon="cog" />
        </button>

        <div className="table-dropdown" style={dropdown ? { display: "block" } : { display: "none" }}>
          <ul>{columns.map((column, i) => renderColumnCheckbox(column, i))}</ul>
        </div>
      </div>

      <Card>
        <table className="hover">
          <thead>
            <tr>{columns.map((column, i) => (column.active ? <th key={i}>{column.name}</th> : null))}</tr>
          </thead>
          <tbody>
            {users.get.map((user, i) => (
              <tr key={user.id} onClick={() => { setmodalEditUserOpen(true); setCurrentUser(user); }}>
                {renderColumns(user, i)}
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <Modal title="Neuer Mitarbeiter" isOpen={modalNewUserOpen} onRequestClose={() => setmodalNewUserOpen(false)}>
        <input type="email" className="form" value={modalEmail} onChange={e => setModalEmail(e.target.value)} placeholder="E-Mail" autoComplete="new-password" />
        <input type="password" className="form" value={modalPassword} onChange={e => setModalPassword(e.target.value)} placeholder="Passwort" autoComplete="new-password" />
        {
          modalLoading ? (
            <div className="loading"><div></div><div></div><div></div></div>
          ) : (
            <button className="btn mb-3" onClick={newUser}>Mitarbeiter erstellen</button>
          )
        }
        <i style={{ color: '#777' }}>Es kann bis zu 60 Sekunden dauern, bis der Mitarbeiter erstellt wurde und in der Liste zu sehen ist. Die Seite muss nicht neu geladen werden.</i>
      </Modal>

      <Modal title="Mitarbeiter bearbeiten" isOpen={modalEditUserOpen} onRequestClose={() => setmodalEditUserOpen(false)}>
        <input type="email" className="form" value={currentUser.email} placeholder="E-Mail" autoComplete="new-password" readOnly />
        <input type="text" className="form" value={currentUser.firstname} onChange={e => setCurrentUser({ ...currentUser, firstname: e.target.value })} placeholder="Vorname" autoComplete="new-password" />
        <input type="text" className="form" value={currentUser.lastname} onChange={e => setCurrentUser({ ...currentUser, lastname: e.target.value })} placeholder="Nachname" autoComplete="new-password" />
        <input type="text" className="form" value={currentUser.photoURL} onChange={e => setCurrentUser({ ...currentUser, photoURL: e.target.value })} placeholder="Bild" autoComplete="new-password" />
        {
          modalLoading ? (
            <div className="loading"><div></div><div></div><div></div></div>
          ) : (
            <div className="row">
              <div className="col-md-6">
                <button className="btn" onClick={updateUser}>Aktualisieren</button>
              </div>
              <div className="col-md-6">
                <button className="btn btn-danger" onClick={deleteUser}>Löschen</button>
              </div>
            </div>
          )
        }
      </Modal>
    </>
  );
};

export default Members;
