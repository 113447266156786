import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/store";
import { Card, Pagetitle, Modal } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import base from "../../config/base";
import { toast } from "react-toastify";

const Downloads = (props) => {
  const { downloads } = useContext(GlobalContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalFile, setModalFile] = useState();
  const [title, setTitle] = useState("");

  const fileUpload = () => {
    setModalLoading(true);
    console.log(modalFile);
    base
      .storage()
      .ref("downloads")
      .child(modalFile.name)
      .put(modalFile)
      .then((file) => {
        file.ref.getDownloadURL().then((link) => {
          base
            .firestore()
            .collection("downloads")
            .add({
              title,
              link,
            })
            .then(() => {
              toast.success("Datei hochgeladen");
              setModalOpen(false);
              setModalLoading(false);
            });
        });
      });
  };

  const deleteDownload = (download) => {
    base
      .storage()
      .refFromURL(download.link)
      .delete()
      .then(() => {
        base
          .firestore()
          .doc(`downloads/${download.id}`)
          .delete()
          .then(() => {
            toast.success("Download entfernt");
          });
      });
  };

  return (
    <>
      <Pagetitle icon="newspaper" title={"Downloads (" + downloads.get.length + ")"} />

      <div className="mb-30">
        <button onClick={() => setModalOpen(true)} className="btn">
          <FontAwesomeIcon icon="plus" /> Datei hochladen
        </button>
      </div>

      <Card>
        <table>
          <thead>
            <tr>
              <th>Titel</th>
              <th width="80px">Löschen</th>
            </tr>
          </thead>
          <tbody>
            {downloads.get.map((download, i) => {
              return (
                <tr key={download.id}>
                  <td>
                    <b>{download.title}</b>
                  </td>
                  <td>
                    <button className="btn btn-danger" onClick={() => deleteDownload(download)}>
                      <FontAwesomeIcon icon="trash-alt" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>

      <Modal title="Neue Datei" isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <input type="text" className="form" placeholder="Titel" value={title} onChange={(e) => setTitle(e.target.value)} />
        <input type="file" onChange={(e) => setModalFile(e.target.files[0])} />
        <br />
        <br />
        {modalLoading ? (
          <div className="loading">
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <button className="btn mb-3" onClick={fileUpload}>
            Datei hochladen
          </button>
        )}
      </Modal>
    </>
  );
};

export default Downloads;
