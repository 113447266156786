import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';


const base = Firebase.initializeApp({
  apiKey: "AIzaSyDvJcNCC_PU9i0GdYBVwp6ZlXaBEwfGAao",
  authDomain: "adema-personalservice.firebaseapp.com",
  databaseURL: "https://adema-personalservice.firebaseio.com",
  projectId: "adema-personalservice",
  storageBucket: "adema-personalservice.appspot.com",
  messagingSenderId: "391905127908",
  appId: "1:391905127908:web:bff571c23cd77848e46bf1",
  measurementId: "G-Q3SQXVZGFP"
});

export default base;
