import React, { useState, useEffect, createContext } from "react";
import app from "../config/base";
// import { toast } from "react-toastify";

// global context
export const GlobalContext = createContext({});

const mql = window.matchMedia(`(min-width: 800px)`);

const GlobalProvider = (props) => {
  // States
  const [user, setUser] = useState("loading");
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState({});
  const [articles, setArticles] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [images, setImages] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(mql.matches);
  const [sidebarDocked, setSidebarDocked] = useState(mql.matches);

  // Functions
  const toggleSidebarOpen = () => {
    if (sidebarOpen && mql.matches) {
      setSidebarDocked(false);
      setSidebarOpen(false);
    } else if (!sidebarOpen && mql.matches) {
      setSidebarDocked(true);
      setSidebarOpen(true);
    } else if (sidebarOpen && !mql.matches) {
      setSidebarDocked(false);
      setSidebarOpen(false);
    } else if (!sidebarOpen && !mql.matches) {
      setSidebarDocked(false);
      setSidebarOpen(true);
    }
  };

  const store = {
    user: { get: user, set: setUser },
    users: { get: users, set: setUsers },
    userData: { get: userData, set: setUserData },
    articles: { get: articles, set: setArticles },
    downloads: { get: downloads, set: setDownloads },
    jobs: { get: jobs, set: setJobs },
    images: { get: images, set: setImages },
    sidebarOpen: { get: sidebarOpen, set: setSidebarOpen, toggle: toggleSidebarOpen },
    sidebarDocked: { get: sidebarDocked, set: setSidebarDocked },
  };

  // Hooks
  useEffect(() => {
    // check if logged in
    app.auth().onAuthStateChanged(setUser);

    // get userData
    if (user && user !== "loading") {
      app
        .firestore()
        .doc("/users/" + user.uid)
        .onSnapshot((doc) => {
          setUserData({
            ...doc.data(),
            id: doc.id,
          });
          // TODO: Fix welcome message
          // toast.success("Wilkommen " + doc.data().firstname);
        });
      app
        .firestore()
        .collection("users")
        .orderBy("firstname", "asc")
        .onSnapshot((docs) => {
          const usersArray = [];
          docs.forEach((doc) => {
            usersArray.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          setUsers(usersArray);
        });
      app
        .firestore()
        .collection("news")
        .orderBy("createdAt", "desc")
        .onSnapshot((docs) => {
          const articlesArray = [];
          docs.forEach((doc) => {
            articlesArray.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          setArticles(articlesArray);
        });
      app
        .firestore()
        .collection("downloads")
        .orderBy("title", "asc")
        .onSnapshot((docs) => {
          const downloadsArray = [];
          docs.forEach((doc) => {
            downloadsArray.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          setDownloads(downloadsArray);
        });
      app
        .firestore()
        .collection("stellenangebote")
        .onSnapshot((docs) => {
          const jobsArray = [];
          docs.forEach((doc) => {
            jobsArray.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          setJobs(jobsArray);
        });
      app
        .storage()
        .ref("uploads")
        .listAll()
        .then((result) => {
          const updatedArray = result.items;
          result.items.forEach((imageRef, i) => {
            imageRef.getDownloadURL().then((url) => {
              updatedArray[i].url = url;
              setImages(updatedArray);
            });
          });
        });
    }

    // check windows size for Sidebar
    const mediaQueryChanged = () => {
      setSidebarOpen(mql.matches);
      setSidebarDocked(mql.matches);
    };
    mql.addListener(mediaQueryChanged);
    return () => {
      mql.removeListener(mediaQueryChanged);
    };
  }, [setSidebarOpen, setSidebarDocked, user]);

  return <GlobalContext.Provider value={store}>{props.children}</GlobalContext.Provider>;
};

export const createUser = (email, password) => {
  return app.auth().createUserWithEmailAndPassword(email, password);
};

export const logOut = () => {
  app.auth().signOut();
};

export default GlobalProvider;
