import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/store";
import { Card, Pagetitle } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./jobs.css";

const Jobs = props => {
  const { jobs } = useContext(GlobalContext);

  return (
    <>
      <Pagetitle icon="id-card" title={"Stellenagebote (" + jobs.get.length + ")"} />

      <div className="mb-30">
        <Link to="/jobs/create" className="btn">
          <FontAwesomeIcon icon="plus" /> Neues Stellenagebot
        </Link>
      </div>

      <Card>
        <table className="hover">
          <thead>
            <tr>
              <th>Titel</th>
              <th>Branche</th>
              <th>Adema</th>
            </tr>
          </thead>
          <tbody>
            {jobs.get.map((job, i) => {
              return (
                <tr key={job.id} onClick={() => props.history.push("/jobs/edit/" + job.id)}>
                  <td><b>{job.title}</b></td>
                  <td>{job.branche}</td>
                  <td>{job.adema}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </>
  );
};

export default Jobs;
